import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { LocalStorageService, filterUndefined } from '@core/shared/util';

import { Profil } from './profil';
import { ProfileRecht } from './profile-recht';
import { RolleName } from './rolle';
import { ProfilActions } from './store/profil.actions';
import { profilSelectQuery } from './store/profil.selectors';

@Injectable({ providedIn: 'root' })
export class UserInfoFacade {
  private readonly _profil$: BehaviorSubject<Profil | undefined> = new BehaviorSubject<Profil | undefined>(undefined);
  readonly profil$: Observable<Profil>;

  readonly profilChanges$: Observable<Profil>;

  private readonly rechte$: Observable<ProfileRecht[] | undefined>;
  private readonly rollen$: Observable<RolleName[] | undefined>;

  get profil(): Profil | undefined {
    return this._profil$.getValue();
  }

  get profilId(): string {
    return this.profil?.id ?? '00000000-0000-0000-0000-000000000000';
  }

  constructor(
    private readonly store$: Store,
    private readonly localStorage: LocalStorageService,
  ) {
    this.profilChanges$ = this.store$.select(profilSelectQuery.PROFIL).pipe(filterUndefined());
    this.profil$ = this.profilChanges$.pipe(first());

    this.rechte$ = this.profil$.pipe(map((profil) => profil.activeOrganisationRechte));
    this.rollen$ = this.profil$.pipe(map((profil) => profil.activeOrganisationRollen));

    this.profil$.subscribe({ next: (profil) => this._profil$.next(profil) });
  }

  loadProfile(): void {
    this.store$.dispatch(ProfilActions.COMPONENT.loadInitial());
  }

  hasRecht(recht: ProfileRecht): boolean {
    if (!this.profil) {
      return false;
    }

    return this.profil.activeOrganisationRechte.some((benutzerRecht) => rechteMatch(benutzerRecht, recht));
  }

  /**
   * Returns an observable that emits true if the user has the given Recht.
   * If the previous backend-request failed or wasn't executed, `undefined` is returned.
   * Optionally it takes a fallback boolean param to be returned if there is no recht or request cannot be executed */
  watchRecht$(recht: ProfileRecht, fallback: boolean): Observable<boolean>;
  watchRecht$(recht: ProfileRecht): Observable<undefined | boolean>;
  watchRecht$(recht: ProfileRecht, fallback?: boolean): Observable<boolean | undefined> {
    return this.rechte$.pipe(
      map((rechte) => (rechte ? rechte.some((benutzerRecht) => rechteMatch(benutzerRecht, recht)) : fallback)),
    );
  }

  hasRolle(rolle: RolleName): boolean {
    if (!this.profil) {
      return false;
    }

    return this.profil.activeOrganisationRollen.some((benutzerRolle) => benutzerRolle === rolle);
  }

  /**
   * Returns an observable that emits true if the user has the given Rolle.
   * If the previous backend-request failed or wasn't executed, `undefined` is returned. */
  watchRolle$(rolle: RolleName): Observable<boolean | undefined> {
    return this.rollen$.pipe(
      map((rollen) => (rollen ? rollen.some((benutzerRolle) => benutzerRolle === rolle) : undefined)),
    );
  }

  selectActiveOrganisation(activeOrganisationId: string): void {
    this.store$.dispatch(
      ProfilActions.COMPONENT.selectActiveOrganisation({
        organisationId: activeOrganisationId,
        rememberSelectedOrganisation: false,
        referrerUrl: '/',
      }),
    );
  }

  selectActiveOrganisationAutomatically(activeOrganisationId: string): void {
    this.store$.dispatch(
      ProfilActions.COMPONENT.selectActiveOrganisationAutomatically({ organisationId: activeOrganisationId }),
    );
  }

  hasRequiredRolesAndRights({ activeOrganisationRechte, activeOrganisationRollen }: Profil): boolean {
    return !!activeOrganisationRechte.length && !!activeOrganisationRollen.length;
  }

  hasOnlyOneAvailableOrganisation({ organisationen, activeOrganisationId }: Profil): boolean {
    const hasSingleOrganisation = organisationen.length === 1;
    const hasActiveOrganisation = activeOrganisationId !== null;
    const hasNoMatchingActiveOrganisationInLocalStorage =
      !this.isActiveOrganisationIdInLocalStorage(activeOrganisationId);

    return hasSingleOrganisation && hasActiveOrganisation && hasNoMatchingActiveOrganisationInLocalStorage;
  }

  isOrganisationSelectionRequired({ organisationen, activeOrganisationId }: Profil): boolean {
    return organisationen.length > 1 && !this.isActiveOrganisationIdInLocalStorage(activeOrganisationId);
  }

  private isActiveOrganisationIdInLocalStorage(activeOrganisationId: string): boolean {
    try {
      const storedValue: string | null = this.localStorage.tryReadingString('activeOrganisationId');
      return storedValue === activeOrganisationId;
    } catch {
      return false;
    }
  }
}

function rechteMatch(a: ProfileRecht, b: ProfileRecht): boolean {
  return a.resource === b.resource && a.action === b.action;
}
